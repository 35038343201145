import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

export const domainStickyImages = () => {
  gsap.to(".js-domain-img-1", {
    y: -300,
    x: 50,
    opacity: 0,
    ease: "Expo.easeIn",
    scrollTrigger: {
      trigger: ".js-domain-sec-1",
      start: "top 0%",
      end: "bottom 20%",
      scrub: 0.5,
    },
  });

  gsap.to(".js-domain-img-2", {
    y: -300,
    x: -40,
    opacity: 0,
    ease: "Expo.easeIn",
    scrollTrigger: {
      trigger: ".js-domain-sec-2",
      start: "top 0%",
      end: "bottom 20%",
      scrub: 0.5,
    },
  });
};
