import $ from "jquery";
import inView from "in-view";
import { handleCountUp } from "./handleCountUp";

export const inview = () => {
  inView(".inview").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });

  inView(".inview-custom").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });

  inView(".js-inview-data-01").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-02").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-03").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-04").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-05").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-06").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-07").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-08").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-09").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-10").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-11").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-12").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-13").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-14").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });

  inView(".js-inview-data-15").once("enter", function (el) {
    $(el)
      .find(".js-count-up")
      .each(function () {
        handleCountUp($(this));
      });
  });
};
