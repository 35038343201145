import $ from "jquery";

export const loadingAnimation = () => {
  if (sessionStorage.getItem("first-visit")) {
    // 2回目以降

    if ($(".js-front-page").length !== 0) {
      shortAnimation();
    }
  } else {
    // 1回目
    sessionStorage.setItem("first-visit", true);

    if ($(".js-front-page").length !== 0) {
      shortAnimation();
    }
  }

  function shortAnimation() {
    if ($("body").hasClass("is-loading")) {
      $("body").removeClass("is-loading");
    }
    $(".js-loading").hide();
    $("body").addClass("is-mv-animation-active");

    const loadingMvTitle = $(".js-loading-mv-title");
    const loadingMvShape = $(".js-loading-mv-shape");
    const loadingMvShapes = $(".js-loading-mv-shapes");
    const loadingMvContent = $(".js-loading-mv-content");
    const loadingMvSubContent = $(".js-loading-mv-sub-content");

    // 4-1. シェイプ出現
    $(loadingMvShape).addClass("is-visible");

    // 4-2. キャッチ出現
    // 5. 小さい球体表示
    // 6. 小さい球体広がる＆MVの他のコンテンツ表示

    setTimeout(() => {
      $(loadingMvTitle).addClass("is-visible");
      $(loadingMvShapes).addClass("is-visible");
    }, 500);

    setTimeout(() => {
      $(loadingMvContent.addClass("is-visible"));
      $("body").removeClass("is-mv-animation-active");
      $("body").addClass("is-mv-animation-done");
    }, 1500);

    setTimeout(() => {
      $(loadingMvSubContent).addClass("is-visible");
    }, 2500);
  }

  function longAnimation() {
    const loading = $(".js-loading");
    const loadingCircles = $(".js-loading-circles");
    const loadingImages = $(".js-loading-images");

    // 1. 開始 - 球体が出てくる
    $("body").addClass("is-loading");
    setTimeout(() => {
      $(loadingCircles).addClass("is-animated");
    }, 500);

    // 2. 画像表示
    setTimeout(() => {
      $(loadingImages).addClass("is-animated");
    }, 3000);

    // 3. ホワイトアウト
    setTimeout(() => {
      $(loading).addClass("is-animation-end");
    }, 6000);

    // 2秒間かけて白くなる
    setTimeout(() => {
      $("body").removeClass("is-loading");

      shortAnimation();
    }, 7500);
  }
};
