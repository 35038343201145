import $ from "jquery";

export const headerShowBtns = () => {
  const trigger = $(".js-header-btns-trigger");

  trigger
    .on("mouseover", function () {
      $(this).addClass("is-active");
      $(this).find(".js-header-btns").addClass("is-active");
    })
    .on("mouseout", function () {
      $(this).removeClass("is-active");
      $(this).find(".js-header-btns").removeClass("is-active");
    });
};
