import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

export const circleParallax = () => {
  if ($(".js-circle-parallax-lg").length !== 0) {
    gsap.to(".js-circle-parallax-lg", {
      y: 1000,
      ease: "power1.in",
      scrollTrigger: {
        trigger: "body",
        start: "top bottom",
        end: "bottom top",
        scrub: 0.5,
      },
    });
  }

  if ($(".js-circle-parallax-md").length !== 0) {
    gsap.to(".js-circle-parallax-md", {
      y: 750,
      ease: "power1.in",
      scrollTrigger: {
        trigger: "body",
        start: "top bottom",
        end: "bottom top",
        scrub: 0.5,
      },
    });
  }

  if ($(".js-circle-parallax-sm").length !== 0) {
    gsap.to(".js-circle-parallax-sm", {
      y: 500,
      ease: "power1.in",
      scrollTrigger: {
        trigger: "body",
        start: "top bottom",
        end: "bottom top",
        scrub: 0.5,
      },
    });
  }
};
