import $ from "jquery";

export const scaleBox = () => {
  const boxes = $(".js-scale-box");

  boxes
    .eq(0)
    .mouseenter(function () {
      $(this).addClass("is-on");
      boxes.eq(1).addClass("is-off");
    })
    .mouseleave(function () {
      $(this).removeClass("is-on");
      boxes.eq(1).removeClass("is-off");
    });

  boxes
    .eq(1)
    .mouseenter(function () {
      $(this).addClass("is-on");
      boxes.eq(0).addClass("is-off");
    })
    .mouseleave(function () {
      $(this).removeClass("is-on");
      boxes.eq(0).removeClass("is-off");
    });
};
