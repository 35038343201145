import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });
// ScrollTrigger.normalizeScroll(true);

export const mvShapesOnScroll = () => {
  if ($(".js-mv-shape-scroll-1").length !== 0) {
    gsap.to(".js-mv-shape-scroll-1", {
      x: 50,
      y: 120,
      ease: "power1.in",
      scrollTrigger: {
        trigger: ".js-mv",
        start: "top center",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  if ($(".js-mv-shape-scroll-2").length !== 0) {
    gsap.to(".js-mv-shape-scroll-2", {
      x: -100,
      y: -120,
      ease: "power1.in",
      scrollTrigger: {
        trigger: ".js-mv",
        start: "top center",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  if ($(".js-mv-shape-scroll-3").length !== 0) {
    gsap.to(".js-mv-shape-scroll-3", {
      x: -100,
      y: 100,
      ease: "power1.in",
      scrollTrigger: {
        trigger: ".js-mv",
        start: "top center",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  if ($(".js-mv-shape-para").length !== 0) {
    gsap.to(".js-mv-shape-para", {
      y: 100,
      ease: "power1.in",
      scrollTrigger: {
        trigger: ".js-mv",
        start: "top center",
        end: "bottom top",
        scrub: 0.5,
      },
    });
  }
};
