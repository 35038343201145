import $ from "jquery";

export const smoothScroll = () => {
  var urlHash = location.hash;
  if (urlHash) {
    $("body,html").stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var headerHeight = $("#header").innerHeight();
      var position = target.offset().top - headerHeight + 8;
      $("body,html").stop().animate({ scrollTop: position }, 500);
    }, 500);
  }
  $('a[href^="#"]').on("click", function () {
    if (!$(this).hasClass("js-no-scroll")) {
      var headerHeight = $("#header").innerHeight();
      var href = $(this).attr("href");
      var target = $(href);
      var position = target.offset().top - headerHeight + 8;
      $("body,html").stop().animate({ scrollTop: position }, 500);
    }
  });
};
