import $ from "jquery";
import Swiper from "swiper/bundle";

export const articleSlider = () => {
  const slider = new Swiper(".js-article-slider", {
    width: 346,
    spaceBetween: 40,
    loop: true,
    loopedSlides: 2,
    navigation: {
      nextEl: ".js-article-slider-next",
      prevEl: ".js-article-slider-prev",
    },
  });
};
