import $ from "jquery";

export const faqAccordion = () => {
  const accordion = $(".js-accordion");
  accordion.find(".js-accordion-content").hide();

  accordion.on("click", function () {
    $(this).toggleClass("is-open");
    $(this).find(".js-accordion-content").slideToggle();
  });
};
