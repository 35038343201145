import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });
// ScrollTrigger.normalizeScroll(true);

export const scrollText = () => {
  gsap.to(".js-scroll-text", {
    x: 500,
    ease: "power1.in",
    scrollTrigger: {
      trigger: ".js-scroll-text-wrapper",
      start: "top center",
      end: "+=1000",
      scrub: 2,
      // markers: true,
    },
  });
};
