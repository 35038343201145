import Swiper from "swiper/bundle";

export const hamburgerSlider = () => {
  const slider = new Swiper(".js-hamburger-slider", {
    width: 245,
    spaceBetween: 16,
    navigation: {
      nextEl: ".js-hamburger-slider-next",
      prevEl: ".js-hamburger-slider-prev",
    },
  });
};
