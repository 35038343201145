import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

export const highlightNav = () => {
  const sections = gsap.utils.toArray("[data-section]");
  const headerHeight = $("#header").innerHeight() + 7;

  sections.forEach((section) => {
    ScrollTrigger.create({
      trigger: section,
      start: `top 116px`,
      end: "bottom 116px",
      onToggle: (self) => {
        const targetName = section.dataset.section;

        if (self.isActive) {
          const target = document.querySelector(
            `[data-section-target="${targetName}"]`
          );
          target.classList.add("is-active");
        } else {
          const target = document.querySelector(
            `[data-section-target="${targetName}"]`
          );
          target.classList.remove("is-active");
        }
      },
    });
  });
};
