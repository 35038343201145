import $ from "jquery";
import { scaleBox } from "./modules/scaleBox";
import { articleSlider } from "./modules/articleSlider";
import { scrollText } from "./modules/scrollText";
import { inview } from "./modules/inview";
import { calc100vhHeight } from "./modules/calc100vhHeight";
import { hamburgerMenu } from "./modules/hamburgerMenu";
import { hamburgerSlider } from "./modules/hamburgerSlider";
import { goTopOnLoad } from "./modules/goTopOnLoad";
import { loadingAnimation } from "./modules/loadingAnimation";
import { mvShapesOnScroll } from "./modules/mvShapesOnScroll";
import { circleParallax } from "./modules/circleParallax";
import { ajaxViewMore } from "./modules/ajaxViewMore";
import { faqAccordion } from "./modules/faqAccordion";
import { smoothScroll } from "./modules/smoothScroll";
import { highlightNav } from "./modules/highlightNav";
import { careerModal } from "./modules/careerModal";
import { diversitySlider } from "./modules/diversitySlider";
import { domainStickyImages } from "./modules/domainStickyImages";
import { handleCountUp } from "./modules/handleCountUp";
import { headerShowBtns } from "./modules/headerShowBtns";
// import { handleVisibilityOnScroll } from "./modules/handleVisibilityOnScroll";

$(function () {
  smoothScroll();
  loadingAnimation();
  scaleBox();
  articleSlider();
  inview();
  calc100vhHeight();
  hamburgerMenu();
  hamburgerSlider();
  goTopOnLoad();
  circleParallax();
  faqAccordion();
  highlightNav();
  careerModal();
  diversitySlider();
  domainStickyImages();
  handleCountUp();
  headerShowBtns();
  // handleVisibilityOnScroll();
  // ajaxViewMore();
});

// Swiperと使うと初回ロード時に動かないので、少し遅らせる
$(function () {
  setTimeout(() => {
    scrollText();
    mvShapesOnScroll();
  }, 1000);
});
