import Swiper from "swiper/bundle";

export const diversitySlider = () => {
  const slider = new Swiper(".js-diversity-slider", {
    slidesPerView: "auto",
    // slidesPerView: "auto",
    loop: true,
    loopedSlides: 2,
    speed: 15000,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
    },
  });
};
