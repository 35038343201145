import $ from "jquery";

export const careerModal = () => {
  const modalBtns = $(".js-career-modal-btn");
  const modalOverlay = $(".js-career-modal-overlay");
  const modalCloseBtns = $(".js-career-modal-close");

  modalBtns.on("click", function () {
    const modalName = $(this).attr("data-modal-btn");
    const targetModal = $(`[data-modal-name="${modalName}"]`);
    targetModal.addClass("is-active");
    $("body").addClass("is-career-modal-open");
  });

  modalOverlay.on("click", closeModal);
  modalCloseBtns.on("click", closeModal);

  function closeModal() {
    $("body").removeClass("is-career-modal-open");
    $(".js-career-modal.is-active").removeClass("is-active");
  }
};
